<template>
  <div class="page">
    <div class="page-header" style="display: none;">
      <a-form-model
        class="ant-advanced-search-form"
        :labelCol="{span: 6}"
        :wrapperCol="{span: 18}"
        :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col
            :span="8"
          >
            <a-form-model-item label="资方编码" prop="fundCode">
              <a-input v-model="form.fundCode" autocomplete="off" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :offset="2" of :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
            <a-button style="margin-left: 12px;" type="default" html-type="button" @click="addFund">
              新增
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-row class="page-content">
      <a-col :span="24">
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :row-key="record => record.fundCode"
        >
        <span slot="action" slot-scope="text, record">
          <a @click="editFund(record)">编辑</a>
        </span>
        </a-table>
      </a-col>
    </a-row>
    <a-modal
      v-model="fundEditing"
      title="更新资方"
      @ok="saveFund"
      okText="保存"
      cancelText="取消"
      >
       <a-form-model
        ref="fundForm"
        :model="fundForm"
        :rules="fundRules"
        :label-col="{span: 4}"
        :wrapper-col="{span: 14}"
      >
        <!-- <a-form-model-item label="资方编码" prop="fundCode">
          <a-input
            v-model="fundForm.fundCode"
          />
        </a-form-model-item>
        <a-form-model-item label="资方名称" prop="fundName">
          <a-input
            v-model="fundForm.fundName"
          />
        </a-form-model-item> -->
        <a-form-model-item label="分流比例" prop="section">
          <a-input-number v-model="fundForm.section" :min="0" :max="100" placeholder="0-100以内的数字" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { fundListQuery, fundDistribution } from '@/api/fund';

const columns = [
  {
    title: '资方编码',
    dataIndex: 'fundCode',
    key: 'fundCode',
  },
  {
    title: '资方名称',
    dataIndex: 'fundName',
    key: 'fundName',
  },
  {
    title: '分流比例',
    dataIndex: 'section',
    key: 'section',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      columns,
      expand: false,
      form: {
        fundCode: '',
      },
      pagination: {
        current: 1,
        total: 0,
      },
      fundEditing: false,
      fundForm: {
        fundCode: '',
        fundName: '',
        section: 0,
      },
      fundRules: {
        fundCode: [
          { required: true, message: '请输入资方编码', trigger: 'blur' },
          {
            min: 1, max: 20, message: '资方编码超出长度限制', trigger: 'blur',
          },
        ],
        fundName: [
          { required: true, message: '请输入资方名称', trigger: 'blur' },
          {
            min: 1, max: 20, message: '资方名称超出长度限制', trigger: 'blur',
          },
        ],
        section: [
          {
            required: true, message: '请输入分流比例', trigger: 'blur',
          },
          {
            type: 'number', min: 0, max: 100, message: '分流比例超出限制', trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    addFund() {
      this.fundForm = {
        fundCode: '',
        fundName: '',
        section: 0,
      };
      this.fundEditing = true;
    },
    editFund(record) {
      this.fundForm = { ...record };
      this.fundEditing = true;
    },
    async saveFund() {
      const { success, errorMsg } = await fundDistribution(this.fundForm);
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }

      this.$message.info('保存成功');
      this.fundEditing = false;
      this.fetchPage();
    },
    handleSearch(e) {
      e.preventDefault();
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      const { result, success, errorMsg } = await fundListQuery({
        ...this.form,
        limit: 10,
        offset: this.pagination.current,
      });
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }
      this.data = result.fundInfoList;
      this.pagination.total = result.total;
    },
  },
  mounted() {
    this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>

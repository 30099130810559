<template>
  <div class="page">
    <a-row class="page-content">
      <a-col :span="24">
        <a-form-model
            ref="RepaymentInfoForm"
            :model="RepaymentInfoForm"
            :rules="RepaymentInfoRules"
            :label-col="{span: 4}"
            :wrapper-col="{span: 14}"
          >
            <a-form-model-item label="渠道方订单号" prop="channelOrderNo">
              <a-input  v-model="RepaymentInfoForm.channelOrderNo" />
            </a-form-model-item>
            <a-form-model-item label="期数" prop="period">
              <a-input  v-model="RepaymentInfoForm.period" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="saveUser">
                推送
              </a-button>
            </a-form-model-item>
          </a-form-model>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import { fixRepayOrderByRepayld } from '@/api/user';
export default {
  name: 'pushRepaymentInfo',
  data() {
    return {
      RepaymentInfoForm: {
        channelOrderNo: '',
        period: '',
      },
      RepaymentInfoRules: {
        channelOrderNo: [
          { required: true, message: '请输入渠道方订单号', trigger: 'blur' },
        ],
        period: [
          { required: true, message: '请输入期数', trigger: 'blur' },
        ]
      },
    };
  },
  methods: {
    async saveUser() {
      this.$refs.RepaymentInfoForm.validate(async valid => {
        if (valid) {
          const msg = await fixRepayOrderByRepayld(this.RepaymentInfoForm);
          // console.log(errorMsg)
          //   if (!success) {
          //     return;
          //   }
          //   this.$message.info('推送成功');
            this.$message.info(msg);
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
   
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>

import axios from '@/utils/axios';

export function fundListQuery(data) {
  return axios({
    url: '/backend/fund/fundListQuery',
    method: 'post',
    data,
  });
}

export function fundDistribution(data) {
  return axios({
    url: '/backend/fund/fundDistribution',
    method: 'post',
    data,
  });
}

<template>
  <div class="page">
    <a-row class="page-content">
      <a-col :span="30">
        <a-form-model
          ref="userForm"
          :model="userForm"
          :rules="userRules"
          :label-col="{span: 10}"
          :wrapper-col="{span: 20}"
        >
          <a-form-model-item label="开始日期" prop="startDay" :style="{ display: 'inline-block'}">
            <a-input
              v-model="userForm.startDay"
              :maxLength="10"
              :style="{width:'120px'}"
              placeholder="YYYY-MM-DD"
            />
          </a-form-model-item>
          <a-form-model-item label="结束日期" prop="endDay" :style="{ display: 'inline-block'}">
            <a-input
              v-model="userForm.endDay"
              :maxLength="10"
              :style="{width:'120px'}"
              placeholder="YYYY-MM-DD"
            />
          </a-form-model-item>
          <a-form-model-item label="渠道编码" prop="channelCode" :style="{ display: 'inline-block'}">
            <a-input
              v-model="userForm.channelCode"
              :maxLength="10"
              :style="{width:'120px'}"
            />
          </a-form-model-item>
          <a-form-model-item :style="{ display: 'inline-block'}">
            <a-button type="primary" @click="showData">
              查询
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
    <a-row class="page-content">
      <a-col>
        <a-table
          :scroll="{ x: 1500 }"
          :columns="aColumns"
          :data-source="aData"
        >
        </a-table>
      </a-col>
    </a-row>
    <a-row class="page-content">
      <a-col>
        <a-table
          :scroll="{ x: 1500 }"
          :columns="lColumns"
          :data-source="lData"
        >
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { showData } from '@/api/statistics';

const aColumns = [
  {
    title: '日期',
    dataIndex: 'd',
    key: 'd',
  },
  {
    title: '渠道编码',
    dataIndex: 'c',
    key: 'c',
  },
  {
    title: '授信申请总笔数',
    dataIndex: 'cnt',
    key: 'cnt',
  },
  {
    title: '授信成功笔数',
    dataIndex: 'cnts',
    key: 'cnts',
  },
  {
    title: '授信成功率%',
    dataIndex: 'r',
    key: 'r',
  },
  {
    title: '授信总金额',
    key: 's',
    dataIndex: 's',
  },
];

const lColumns = [
  {
    title: '日期',
    dataIndex: 'd',
    key: 'd',
  },
  {
    title: '渠道编码',
    dataIndex: 'c',
    key: 'c',
  },
  {
    title: '借款申请总笔数',
    dataIndex: 'cnt',
    key: 'cnt',
  },
  {
    title: '借款总金额',
    dataIndex: 's',
    key: 's',
  },
  {
    title: '借款通过笔数',
    dataIndex: 'lc',
    key: 'lc',
  },
  {
    title: '借款成功率%',
    dataIndex: 'r',
    key: 'r',
  },
  {
    title: '当日成功放款笔数',
    key: 'lcs',
    dataIndex: 'lcs',
  },
  {
    title: '放款总金额',
    key: 'ss',
    dataIndex: 'ss',
  },
];

export default {
  name: 'Home',
  data() {
    return {
      aData: [],
      lData: [],
      aColumns,
      lColumns,
      expand: false,
      userForm: {
        startDay: '',
        endDay: '',
      },
      userRules: {
        startDay: [
          { required: true, message: '请输入日期', trigger: 'blur' },
          {
            min: 10, max: 10, message: '日期格式不对', trigger: 'blur',
          },
        ],
        endDay: [
          { required: true, message: '请输入日期', trigger: 'blur' },
          {
            min: 10, max: 10, message: '日期格式不对', trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    async showData() {
      this.loading = true;
      const { result, success, errorMsg } = await showData(this.userForm);
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
      }
      this.aData = result.authApplyFlowList;
      this.lData = result.loanOrderFlowList;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}
.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
